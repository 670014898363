import React, { useEffect, useState, Component } from "react";
import MyNavBar from "./myNavBar.js";
import Leaflet from "leaflet";
import { Map, CircleMarker, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { parse } from "react-json-parser";
import axios from "axios";
import MyScreenFooter from "./myScreenFooter.js";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, CardHeader, CardText } from "reactstrap";
import { Chart } from "react-google-charts";

let basurl = window.location.hostname;
let userName = localStorage.getItem("UserName");
let companyId = localStorage.getItem("CompanyID");
let operationCenterID = localStorage.getItem("OperationCenterID");

function MyDashBoardScreen(params) {
  const [yearlyTotal, setYearlyTotal] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [weeklyTotal, setWeeklyTotal] = useState(0);
  const [dailyTotal, setDailyTotal] = useState(0);
  const [data, setData] = useState([
    ['Month', 'Sales', 'Expenses', 'Profit'],
    ['09', 1000, 400, 200],
    ['10', 1170, 460, 250],
    ['11', 660, 1120, 300],
    ['12', 1030, 540, 350],
  ])


  useEffect(() => {
    const url = `http://${basurl}:8081/getSafeAndDeliveryTotalsAPI`;
    axios
      .get(url, {
        params: {
          UserName: userName,
          CompanyId: companyId,
          TimePeriod: "YEAR"
        },
      })
      .then((res1) => {
        setYearlyTotal(res1.data.reduce((currentTotal, item) => {
          return item.TotalAmount + currentTotal
        }, 0).toFixed(2));
      });
    axios
      .get(url, {
        params: {
          UserName: userName,
          CompanyId: companyId,
          TimePeriod: "MONTH"
        },
      })
      .then((res1) => {
        setMonthlyTotal(res1.data.reduce((currentTotal, item) => {
          return item.TotalAmount + currentTotal
        }, 0).toFixed(2));
      });
    axios
      .get(url, {
        params: {
          UserName: userName,
          CompanyId: companyId,
          TimePeriod: "WEEK"
        },
      })
      .then((res1) => {
        setWeeklyTotal(res1.data.reduce((currentTotal, item) => {
          return item.TotalAmount + currentTotal
        }, 0).toFixed(2));
      });
    axios
      .get(url, {
        params: {
          UserName: userName,
          CompanyId: companyId,
          TimePeriod: "DAY"
        },
      })
      .then((res1) => {
        setDailyTotal(res1.data.reduce((currentTotal, item) => {
          return item.TotalAmount + currentTotal
        }, 0).toFixed(2));
      });
  }, []);

  return (
    <div className="App">
      <div className="Form1">
        <MyNavBar
          userName={userName}
          conpanyID={companyId}
          style={{ zIndex: 10 }}
        ></MyNavBar>
      </div>
      <div className="Form1">
        <Container fluid className="border border-info">
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0" >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Daily Total
                        </CardTitle>
                        <br></br>
                        <span className="h2 font-weight-bold mb-0">
                          {dailyTotal ? dailyTotal : "0.00"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Weekly Total
                        </CardTitle>
                        <br></br>
                        <span className="h2 font-weight-bold mb-0">
                          {weeklyTotal ? weeklyTotal : "0.00"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Monthly Total
                        </CardTitle>
                        <br></br>
                        <span className="h2 font-weight-bold mb-0">
                          {monthlyTotal ? monthlyTotal : "0.00"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Yearly Total
                        </CardTitle>
                        <br></br>
                        <span className="h2 font-weight-bold mb-0">
                          {yearlyTotal ? yearlyTotal : "0.00"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>&nbsp;
        <Container fluid className="border border-info">
          <Row>
            <Col lg="6">
              <Chart
                height={'300px'}
                chartType="Bar"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                  // Material design options
                  chart: {
                    title: 'Company Performance',
                    subtitle: 'Sales, Expenses, and Profit: 09.2020-12.2020',
                  },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
              />
            </Col>
            <Col lg="6">
            </Col>
          </Row>
        </Container>
      </div>
      <div className="Form1">
        <MyScreenFooter
          screenName="Dashboard"
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>
    </div >
  );
}

export default MyDashBoardScreen; //exporting a component make it reusable and this is the beauty of react
