import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import Style from '../App.css'
import ServerTable from 'react-strap-table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import MyNavBar from '../myNavBar.js'
import MyScreenFooter from '../myScreenFooter.js'
import Modal from 'react-modal'
import moment from "moment";
import { Container, Row, Col } from 'react-bootstrap';

function MySafeAndDeliveryTransactionReport(params) {
  const [orders, setOrders] = useState([])
  const [open, setOpen] = useState(0);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [customerTypeId, setCustomerTypeId] = useState(0);

  const [beginDate, setBeginDate] = useState(moment().subtract(365, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));

  const openModal = () => setOpen(1);
  const closeModal = (() => { setOpen(0) });

  // For getting search result report. İt will be useful
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalLineCount, setTotalLineCount] = useState(0);

  let basurl = window.location.hostname;
  let userName = localStorage.getItem("userName");
  let companyId = localStorage.getItem("CompanyID");

  function compare(a, b) {
    const pkA = a.Id;
    const pkB = b.Id;

    let comparison = 0;

    if (pkA > pkB) {
      comparison = 1;
    } else if (pkA < pkB) {
      comparison = -1;
    }
    return comparison;
  }

  useEffect(() => {
    const url1 = `http://${basurl}:8081/getCustomerTypesAPI`
    axios.get(url1, {
      params: {
        userName: userName,
        companyId: companyId
      }
    })
      .then(res1 => {
        setCustomerTypes(res1.data);
      });
  }, [])

  function getSafeAndDeliveryTransactionReport(event) {
    event.preventDefault();
    setOrders([]);
    const url = `http://${basurl}:8081/mySafeAndDeliveryTransactionReportAPI`
    axios.get(url, {
      params: {
        UserName: userName,
        CompanyId: companyId,
        CustomerTypeId: customerTypeId,
        BeginDate: beginDate,
        EndDate: endDate
      }
    })
      .then(res => {
        console.log(res.data)
        const orders = res.data.sort(res.data.Id);
        //we did the sorting
        setOrders(orders.sort(compare));
      });
    renderTableData()
  }
  // console.log("totalAmount");
  // console.log(totalAmount);
  // orders.map(e => console.log(e.TotalAmount));
  useEffect(() => {
    setTotalAmount(orders.reduce((currentTotal, item) => {
      return item.TotalAmount + currentTotal
    }, 0).toFixed(2));
    setTotalLineCount(orders.length);
  }, [orders])

  function renderTableData() {
    return orders.map((order, index) => {
      const { Id, OrderCode, OrderStatus, OrderDate, ExpectedDeliveryDate, Customer, Notes, OrderDetails, EnteredBy, CreatedDate, TotalAmount, CustomerType } = order //destructuring
      return (
        <tr key={Id}>
          <td><input type="checkbox" value={Id} name="Id" />&nbsp;</td>
          <td>{OrderCode}</td>
          <td>{OrderStatus}</td>
          <td>{OrderDate}</td>
          <td>{ExpectedDeliveryDate}</td>
          <td>{Customer}</td>
          <td>{CustomerType}</td>
          <td>{Notes}</td>
          <td>{OrderDetails}</td>
          <td>{TotalAmount}</td>
          <td>{EnteredBy}</td>
          <td>{CreatedDate}</td>
        </tr>
      )
    })
  }


  return (
    <div className="master">
      <div className="MasterTitle">
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(0)}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            backgroundOpacity: "0.8"
          },
          content: {
            left: "25%",
            right: "25%",
            bottom: "50%",
            position: 'absolute',
            color: "#10375c",
            width: "50%"
          }
        }}
      >
        <form>
          <Container fluid className="border border-success">
            <Row>
              <Col>
                <h2>Fill Criteria Info!</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <select name="customerTypeId" required value={customerTypeId} onChange={e => setCustomerTypeId(e.target.value)} className="form-control">
                  <option value="0" disabled selected>Müşteri Tipi Seçiniz...</option>
                  {customerTypes.map(data => <option key={data.ID} value={data.ID}>{data.Name}</option>)}</select>
              </Col>
            </Row>&nbsp;
            <Row>
              <Col sm={2}>
                <label>Start Date:</label>
              </Col>
              <Col sm={4}>
                <input
                  type="date"
                  defaultValue={beginDate}
                  required
                  name="beginDate"
                  onChange={(e) => setBeginDate(e.target.value)}
                  className="form-control"
                />
              </Col>
              <Col sm={2}>
                <label>End Date:</label>
              </Col>
              <Col sm={4}>
                <input
                  type="date"
                  defaultValue={endDate}
                  required
                  name="endDate"
                  onChange={(e) => setEndDate(e.target.value)}
                  className="form-control"
                />
              </Col>
            </Row>&nbsp;
          </Container>
          <br></br>
          <button value="closeModal" className="btn btn-popup-success" onClick={closeModal}>Close Criteria Screen</button>
        </form>
      </Modal>

      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>
      <div className="Form1">
        <form className="Form2">
          <button type="submit" style={{ width: "40%", height: "60px" }} onClick={getSafeAndDeliveryTransactionReport} value="Submit" className="btn btn-success">Get Result</button>&nbsp;&nbsp;
          <button type="button" style={{ width: "40%", height: "60px" }} onClick={openModal} value="updateCriteras" className="btn btn-warning">Show Criteria Screen</button>
        </form>
      </div>
      <div className="Form1">
        <table id="table-to-xls" className="Form1">
          <tr>
            <th>Select</th>
            <th>Order Code</th>
            <th>Statü</th>
            <th>Sipariş Tarihi</th>
            <th>Beklenen Teslimat Tarihi</th>
            <th>Müşteri</th>
            <th>Müşteri Tipi</th>
            <th>Not</th>
            <th>Sipariş Detayı</th>
            <th>Toplam Fiyatı</th>
            <th>Kaydeden</th>
            <th>Kayıt Tarihi</th>
          </tr>
          <tbody>
            {renderTableData()}
          </tbody>
          <tr>
            <th colspan="10"></th>
          </tr>
          <tr>
            <th colspan="3">Total Line Count:{totalLineCount}</th>
            <th colspan="2">Totals :</th>
            <th colspan="3"></th>
            <th></th>
            <th>{totalAmount}</th>
            <th></th>
            <th></th>
          </tr>
        </table>
      </div>
      <div className="Form1">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Download as XLS" />
      </div>
      <div className="Form1">
        <MyScreenFooter screenName="Safe&Delivery Transaction Report" userNameFooter={userName} conpanyIDFooter={companyId}></MyScreenFooter>
      </div>

    </div>
  )
}

export default MySafeAndDeliveryTransactionReport;
