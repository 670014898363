import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MyScreenFooter from "./myScreenFooter.js";
import Select from "react-select";
import MyNavBar from "./myNavBar.js";
import Modal from "react-modal";
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from './reports/myInvoicePrintOut'
import moment from "moment";

let basurl = window.location.hostname;
let userName = localStorage.getItem("UserName");
let companyId = localStorage.getItem("CompanyID");
let operationCenterID = localStorage.getItem("OperationCenterID");


function MyOrdersScreen(params) {
  const [customers, setCustomers] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [products, setProducts] = useState([]);
  const [customerId, setCustomerId] = useState(0);
  const [productId, setProductId] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [salesPrice, setSalesPrice] = useState(0);
  const [note, setNote] = useState("");
  const [orderDate, setOrderDate] = useState(moment().format("YYYY-MM-DD"));
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));

  const [orderTypes, setOrderTypes] = useState([]);
  const [orderTypeId, setOrderTypeId] = useState(1);
  //Screen design for all order operations
  const [showOperationCompleted, setShowOperationCompleted] = useState(false);
  const [showInsertOrderDetailScreen, setShowInsertOrderDetailScreen] = useState(false);


  const handleCloseOperationCompleted = () => {
    setShowOperationCompleted(0);
    window.location.href = `/MyOrdersScreen/`;
  };

  useEffect(() => {
    const url = `http://${basurl}:8081/getCustomersAPI`;
    axios
      .get(url, {
        params: {
          UserName: userName,
          CompanyId: companyId,
        },
      })
      .then((res) => {
        const customers = res.data;
        setCustomers(customers);
      });

    const url1 = `http://${basurl}:8081/getProductsAPI`;
    axios
      .get(url1, {
        params: {
          UserName: userName,
          CompanyId: companyId,
        },
      })
      .then((res1) => {
        const products = res1.data;
        console.log(products);
        setProducts(products);
      });

    const url2 = `http://${basurl}:8081/getOrderTypesAPI`;
    axios
      .get(url2, {
        params: {
          UserName: userName,
          CompanyId: companyId,
        },
      })
      .then((res2) => {
        setOrderTypes(res2.data);
      });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    fetch(`http://${basurl}:8081/myOrderImportAPI`, {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserName: userName,
        CompanyId: companyId
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data === 1) {
        }
      })
      .catch((err) => console.log(err.response.data));
  }

  //select combobox design. it is important
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "35px",
      height: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  };

  const handleDeleteOperations = (e) => {
    setOrderDetails(orderDetails.filter((b) => b.Id != e));
  };

  const handleInsertDetailOperation = () => { setShowInsertOrderDetailScreen(true) };

  const handleInsertOrderDetailScreenAndAddNew = () => {
    orderDetails.push({
      Id: orderDetails.length === 0 ? 1 : Math.max.apply(Math, orderDetails.map(function (o) { return o.Id + 1; })),
      productId: productId,
      Product: products.find((e) => e.value === parseInt(productId)).label,
      UnitSalesPrice: products.find((e) => e.value === parseInt(productId)).SalesPrice,
      Quantity: parseFloat(quantity),
      unitId: products.find((e) => e.value === parseInt(productId)).UnitID,
      unit: products.find((e) => e.value === parseInt(productId)).Unit
    });
    setProductId(0);
    setQuantity(0);
    setShowInsertOrderDetailScreen(true);

  };
  const handleInsertOrderDetailScreen = () => {

    orderDetails.push({
      Id: orderDetails.length === 0 ? 1 : Math.max.apply(Math, orderDetails.map(function (o) { return o.Id + 1; })),
      productId: productId,
      Product: products.find((e) => e.value === parseInt(productId)).label,
      UnitSalesPrice: products.find((e) => e.value === parseInt(productId)).SalesPrice,
      Quantity: parseFloat(quantity),
      unitId: products.find((e) => e.value === parseInt(productId)).UnitID,
      unit: products.find((e) => e.value === parseInt(productId)).Unit
    })

    setShowInsertOrderDetailScreen(false);
    setProductId(0);
    setQuantity(0);
  };

  const componentRef = useRef();
  const handleInvoicePrintOutOperation = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => setShowOperationCompleted(1)
  });


  function handleOrderAndDetailImport(e) {
    fetch(`http://${basurl}:8081/myOrderAndDetailImportAPI`, {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserName: userName,
        CompanyId: companyId,
        CustomerId: customerId,
        OrderDate: orderDate,
        ExpectedDeliveryDate: expectedDeliveryDate,
        Note: note,
        OrderDetails: orderDetails,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        let insertResult = data;
        if (insertResult === 1) {
          window.location.href = `/MyOrdersScreen/`;
        }
      })
      .catch((err) => console.log(err.response.data));
  }

  return (
    <div className="App">
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>

      <div hidden>
        <ComponentToPrint ref={componentRef}
          customer={customerId != 0 ? customers.find((e) => e.value === customerId).label : ""}
          expectedDeliveryDate={expectedDeliveryDate}
          orderDate={orderDate}
          note={note}
          orderDetails={orderDetails}
        />
      </div>

      <form onSubmit={handleSubmit} className="Form2">
        <Container fluid className="border border-warning">
          <Row>
            <Col sm={8}>
              <Select name="customerId" value={customers.find((e) => e.value === parseInt(customerId))} placeholder="Select Customer" styles={customStyles} onChange={e => setCustomerId(e.value)} options={customers} />
            </Col>
            <Col sm={4}>
              <Select name="orderTypeId" value={orderTypes.find((e) => e.value === parseInt(orderTypeId))} placeholder="Select Order Type" styles={customStyles} onChange={e => setOrderTypeId(e.value)} options={orderTypes} />
            </Col>
          </Row>
        &nbsp;&nbsp;
          <Row>
            <Col sm={6}>
              <label>Order Date: </label>
              <input
                type="date"
                required
                value={orderDate}
                name="orderDate"
                onChange={(e) => setOrderDate(e.target.value)}
                className="form-control"
              />
            </Col>
            <Col sm={6}>
              <label>Expected Delivery Date: </label>
              <input
                type="date"
                required
                value={expectedDeliveryDate}
                name="expectedDeliveryDate"
                onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <textarea
                rows="3"
                placeholder="If you have extra information, please fill here!"
                name="note"
                onChange={(e) => setNote(e.target.value)}
                className="form-control"
              />
            </Col>
          </Row>
        </Container>
        &nbsp;
        <Container fluid className="">
          <Row>
            <Col sm={4}>
              <button
                type="button"
                value="button"
                className="btn btn-success"
                onClick={handleInsertDetailOperation}
              > Add Product
        </button>
            </Col>
            <Col sm={4}>
              <button
                type="button"
                value="button"
                className="btn btn-info"
                onClick={handleInvoicePrintOutOperation}
              > Create Invoice
        </button>
            </Col>
            <Col sm={4}>
              <button
                type="button"
                value="button"
                className="btn btn-warning"
                onClick={handleOrderAndDetailImport}
              > Save
        </button>
            </Col>
          </Row>
        </Container>
        &nbsp;&nbsp;
        <Container fluid className="border border-info">
          &nbsp;
          <Row>
            <Col sm={12}>
              <table>
                <thead>
                  <tr>
                    <th><input type="checkbox" name="masterCheckbox" /></th>
                    <th>Order</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody className="show-grid">
                  {orderDetails.map((e, index) =>
                    <tr className="tableRow" key={e.Id}>
                      <td><input type="checkbox" name="Id" />&nbsp;</td>
                      <td>{e.Id}</td>
                      <td>{e.Product}</td>
                      <td>{e.Quantity}</td>
                      <td>{e.unit}</td>
                      <td>{e.UnitSalesPrice}</td>
                      <td style={{ backgroundColor: "#a8dda8", color: "#2d6187", textAlign: "center", fontWeight: "bolder", fontSize: "20px" }}>{e.UnitSalesPrice * e.Quantity}</td>
                      <td><Button className="btn btn-info" style={{ minHeight: "30px", fontWeight: "bolder", color: "black" }} onClick={() => handleDeleteOperations(e.Id)}><span>Delete</span></Button></td>
                    </tr>)}
                </tbody>
              </table>
            </Col>
          </Row>
        &nbsp;&nbsp;
        </Container>
      </form>

      <div className="Form1">
        <MyScreenFooter
          screenName={"Order Management Screen"}
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>

      <Modal
        isOpen={showOperationCompleted}
        onRequestClose={() => setShowOperationCompleted(false)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "45%" }
        }}
      >
        <h2>Info!</h2>
        <br></br>
        <p>Operation completed.You can check order from "Order Pool" screen</p>
        <div>
          <button value="setShowOperationCompleted" className="btn btn-success" onClick={handleCloseOperationCompleted}>Close Screen</button>
        </div>
      </Modal>

      <Modal
        isOpen={showInsertOrderDetailScreen}
        onRequestClose={() => setShowInsertOrderDetailScreen(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "45%" }
        }}
      >
        <Container fluid className="border border-info">&nbsp;
          <Row>
            <Col sm={4}>
              <Select name="productId" required placeholder="Ürün Seçiniz" styles={customStyles} onChange={e => setProductId(e.value)} options={products} />
            </Col>
            <Col sm={4}>
              <input
                type="number"
                required
                value={quantity}
                name="quantity"
                onChange={(e) => setQuantity(e.target.value)}
                className="form-control"
              />
            </Col>
            <Col sm={4}>
              <p className="btn btn-success">{productId ? "Birim: " + products.find((e) => e.value === parseInt(productId)).Unit + " Birim Fiyat: " + products.find((e) => e.value === parseInt(productId)).UnitSalesPrice : "Select Product"}</p>
            </Col>
          </Row>&nbsp;
        </Container>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Container fluid >
          <Row>
            <Col sm={6}>
              <button value="setShowOperationCompleted" className="btn btn-info" onClick={handleInsertOrderDetailScreenAndAddNew}>Add New Product</button>
            </Col>
            <Col sm={6}>
              <button value="setShowOperationCompleted" className="btn btn-warning" onClick={handleInsertOrderDetailScreen}>Add and Close</button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </div >
  );
}

export default MyOrdersScreen;
