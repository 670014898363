import React, { useState, useEffect } from 'react'
import axios from 'axios';
import MyNavBar from '../myNavBar.js'
import MyScreenFooter from '../myScreenFooter.js'
import Modal from 'react-modal'
import ConvertToBase64 from '../../tools/convertToBase64.js'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Container, Row, Col } from 'react-bootstrap';

function MyProductManagementScreen(params) {
  const [suppliers, setSuppliers] = useState([])
  const [productList, setProductList] = useState([])
  const [supplierId, setSupplierId] = useState(0)
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [barcode, setBarcode] = useState('')
  const [purchasePrice, setPurchasePrice] = useState(null)
  const [salesPrice, setSalesPrice] = useState(null);
  const [myFiles, setMyFiles] = useState([]);
  const [selected, setSelected] = useState(0)
  const [warningMessage, setWarningMessage] = useState("")
  const [edit, setEdit] = useState(0)
  const [selectProductId, setSelectProductId] = useState(0)

  const [type, setType] = useState("")
  const [size, setSize] = useState(0)
  const [openTypeWarning, setOpenTypeWarning] = useState(0)
  const [openSizeWarning, setOpenSizeWarning] = useState(0)
  const [openSelectionWarning, setOpenSelectionWarning] = useState(0)
  const [units, setUnits] = useState([])
  const [unitId, setUnitId] = useState(0)
  const [active, setActive] = useState(true)

  const [uploadedFile, setUploadedFile] = useState([])

  const closeTypeWarningModal = () => setOpenTypeWarning(0);
  const closeSizeWarningModal = () => setOpenSizeWarning(0);
  const closeSelectionWarningModal = () => setOpenSelectionWarning(0);

  const formData = new FormData();
  let basurl = window.location.hostname
  let userName = localStorage.getItem("UserName");
  let companyId = localStorage.getItem("CompanyID");

  function getFiles(files) {
    setType(files[0]["type"])
    setSize(files[0]["size"])
    if ((files[0]["type"] != String("image/jpeg"))) {
      setOpenTypeWarning(1);
      setUploadedFile([]);
    }
    if (files[0]["size"] > 3000000) {
      setOpenSizeWarning(1);
      setUploadedFile([]);
    }
    //  console.log(files[0]);
    // Convert file to base64 string
    setMyFiles(files[0]["base64"]);
  };

  useEffect(() => {

    const url1 = `http://${basurl}:8081/getSuppliersAPI`
    axios.get(url1, {
      params: {
        userName: userName,
        companyId: companyId
      }
    })
      .then(res1 => {
        const suppliers = res1.data;
        setSuppliers(suppliers)
      });

    const url2 = `http://${basurl}:8081/getUnitsAPI`
    axios.get(url2, {
      params: {
        userName: userName,
        companyId: companyId
      }
    })
      .then(res2 => {
        const units = res2.data;
        setUnits(units)
      })
  }, [])

  function handleSubmit(event) {
    event.preventDefault();
    fetch(`http://${basurl}:8081/myProductImportAPI`, {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userName: userName,
        companyId: companyId,
        SupplierID: supplierId,
        Code: code,
        Name: name,
        UnitId: unitId,
        Barcode: barcode,
        PurchasePrice: purchasePrice,
        SalesPrice: salesPrice,
        FormData: myFiles,
        Operation: edit,
        EditedProductId: selectProductId,
        IsActive: active ? 1 : 0
      })
    })
      .then(res => res.json())
      .then(data => {
        let insertResult = data
        if (insertResult === 1) {
          window.location.href = `/myProductManagementScreen/`;
        }
      })
      .catch(err => console.log(err));
  }

  function compare(a, b) {
    const pkA = a.Id;
    const pkB = b.Id;

    let comparison = 0;

    if (pkA > pkB) {
      comparison = -1;
    } else if (pkA < pkB) {
      comparison = 1;
    }
    return comparison;
  }

  function getAllProductList(event) {
    event.preventDefault();
    const url = `http://${basurl}:8081/getAllProductList`
    axios.get(url, {
      params: {
        userName: userName,
        companyId: companyId,
        Code: code
      }
    })
      .then(res => {
        const productList = res.data.sort(res.data.Id);
        setProductList(productList.sort(compare));
        productList.forEach(product => product["selected"] = 0)
      });
    renderTableData();
  }

  function handleEditClick() {
    //console.log(productList.filter(product=>product["selected"]===1));
    if (productList.filter(product => product["selected"] === 1).length === 0) {
      setWarningMessage("Herhangi bir malzeme seçilmemiş. Lütfen seçip tekrar deneyin.")
      setOpenSelectionWarning(1)
    }
    if (productList.filter(product => product["selected"] === 1).length > 1) {
      setWarningMessage("Birden fazla malzeme seçilmiş. Lütfen kontrol edip tekrar deneyin.")
      setOpenSelectionWarning(1)
    }
    if (productList.filter(product => product["selected"] === 1).length === 1) {
      //console.log(productList.filter(product=>product["selected"]===1));
      setSelectProductId((productList.filter(product => product["selected"] === 1))[0]["Id"])
      setCode((productList.filter(product => product["selected"] === 1))[0]["Code"]);
      setName((productList.filter(product => product["selected"] === 1))[0]["Name"]);
      setUnitId((productList.filter(product => product["selected"] === 1))[0]["UnitID"]);
      setBarcode((productList.filter(product => product["selected"] === 1))[0]["Barcode"]);
      setSupplierId((productList.filter(product => product["selected"] === 1))[0]["SupplierID"]);
      setPurchasePrice((productList.filter(product => product["selected"] === 1))[0]["PurchasePrice"] ? (productList.filter(product => product["selected"] === 1))[0]["PurchasePrice"] : null);
      setSalesPrice((productList.filter(product => product["selected"] === 1))[0]["SalesPrice"] ? (productList.filter(product => product["selected"] === 1))[0]["SalesPrice"] : null);
      setActive((productList.filter(product => product["selected"] === 1))[0]["IsActive"])
      setEdit(1)
    }
  };

  function handleSelection(e) {

    let selectedID = e.target.value
    productList.map((product) => {
      if (product["Id"] === parseInt(selectedID) & product["selected"] === 0) {
        product["selected"] = 1
      } else if (product["Id"] === parseInt(selectedID) & product["selected"] === 1) {
        product["selected"] = 0
      }
    });
    //setSelectionWarning(selectedOrders.length>0?1:0)
  }

  function renderTableData() {
    return productList.map((product, index) => {
      const { Id, Code, Name, Unit, Barcode, Supplier, PurchasePrice, SalesPrice, IsActive } = product //destructuring
      return (
        <tr key={Id}>
          <td><input type="checkbox" selected={selected} onChange={handleSelection} value={Id} name="Id" />&nbsp;</td>
          <td>{Id}</td>
          <td>{Code}</td>
          <td>{Name}</td>
          <td>{Unit}</td>
          <td>{Barcode}</td>
          <td>{Supplier}</td>
          <td>{PurchasePrice}</td>
          <td>{SalesPrice}</td>
          <td><input type="checkbox" checked={IsActive ? 1 : 0} />&nbsp;</td>
        </tr>
      )
    })
  }

  //<input  type="file" name="productImage" onChange={(e)=>fileHandling(e)} className="form-control"/><br></br>
  return (
    <div>
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>
      <form className="Form2">
        <Container fluid className="border border-info">
          <Row>
            <Col sm={4}>
              <input type="text" placeholder="Kod" required name="code" value={code} onChange={e => setCode(e.target.value)} className="form-control" />
            </Col>
            <Col sm={8}>
              <input type="text" placeholder="Tanım" required name="name" value={name} onChange={e => setName(e.target.value)} className="form-control" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <select name="unitId" required value={unitId} onChange={e => setUnitId(e.target.value)} className="form-control">
                <option value="0" disabled selected>Birim Seçiniz...</option>
                {units.map(data => <option key={data.value} value={data.value}>{data.label}</option>)}</select>
            </Col>
            <Col sm={6}>
              <select name="supplierId" required value={supplierId} onChange={e => setSupplierId(e.target.value)} className="form-control">
                <option value="0" disabled selected>Tedarikçi Seçiniz...</option>
                {suppliers.map(data => <option key={data.ID} value={data.ID}>{data.FullName}</option>)}</select>
            </Col>
          </Row>&nbsp;
          <Row>
            <Col sm={6}>
              <input type="text" placeholder="Barkod" required name="barcode" value={barcode} onChange={e => setBarcode(e.target.value)} className="form-control" />
            </Col>
            <Col sm={6}>
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-info">
          <Row>
            <Col sm={4}>
              <input type="number" placeholder="Alış Fiyatı" value={purchasePrice} name="purchasePrice" onChange={e => setPurchasePrice(e.target.value)} className="form-control" />
            </Col>
            <Col sm={4}>
              <input type="number" placeholder="Satış Fiyatı" value={salesPrice} name="salesPrice" onChange={e => setSalesPrice(e.target.value)} className="form-control" />
            </Col>
            <Col sm={4}>
              <b>Aktif?</b>&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="checkbox" checked={active} required name="active" onChange={e => setActive(e.target.checked)} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <label className="grey-text"> Ürün Fotosu:</label>
              {myFiles.length != 0 ?
                <img style={{ height: "150px" }} src={myFiles} />
                : null}
              <ConvertToBase64 multiple={true} onDone={getFiles} />
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-info">
          &nbsp;
          <Row>
            <Col sm={4}>
              <button type="button" onClick={handleSubmit} className="btn btn-success">Kaydet</button>
            </Col>
            <Col sm={4}>
              <button type="button" onClick={getAllProductList} className="btn btn-warning">Malzemeleri Listele</button>
            </Col>
            <Col sm={4}>
              <button type="button" onClick={handleEditClick} className="btn btn-Info">Düzenle</button>
            </Col>
          </Row>&nbsp;
        </Container>
      </form>

      <div className="Form1">
        <table id="table-to-xls" className="Form1">
          <tr>
            <th>Seç</th>
            <th>Id</th>
            <th>Kod</th>
            <th>Tanım</th>
            <th>Birim</th>
            <th>Barkod</th>
            <th>Tedarikçi</th>
            <th>Alış Fiyatı</th>
            <th>Satış Fiyatı</th>
            <th>Aktif?</th>
          </tr>
          <tbody>
            {renderTableData()}
          </tbody>
        </table>
      </div>
      <div className="Form1">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Download as XLS" />
      </div>

      <div className="Form1">
        <MyScreenFooter screenName="Malzeme Tanımlama Ekranı" userNameFooter={userName} conpanyIDFooter={companyId}></MyScreenFooter>
      </div>
      <Modal
        isOpen={openTypeWarning}
        onRequestClose={() => setOpenTypeWarning(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "30%" }
        }}
      >
        <h2>Uyarı!!!!</h2>
        <p>Yüklenen dosya formatı hatalı. Ancak Fotoğraf yükleyebilirsiniz. Lütfen kontrol edip tekrar deneyiniz!</p>
        <br></br>
        <div>
          <button value="closeSelectionModal" className="btn btn-success" onClick={closeTypeWarningModal}>Kapat</button>
        </div>
      </Modal>
      <Modal
        isOpen={openSizeWarning}
        onRequestClose={() => setOpenSizeWarning(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "30%" }
        }}
      >
        <h2>Uyarı!!!!</h2>
        <p>Yüklenen dosya boyutu hatalı. Maksimum 1MB boyutunda dosya yükleyebilirsiniz. Lütfen kontrol edip tekrar deneyiniz!</p>
        <br></br>
        <div>
          <button value="closeSelectionModal" className="btn btn-success" onClick={closeSizeWarningModal}>Kapat</button>
        </div>
      </Modal>

      <Modal
        isOpen={openSelectionWarning}
        onRequestClose={() => setOpenSelectionWarning(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "30%" }
        }}
      >
        <h2>Uyarı!!!!</h2>
        <p>{warningMessage}</p>
        <br></br>
        <div>
          <button value="closeSelectionModal" className="btn btn-success" onClick={closeSelectionWarningModal}>Kapat</button>
        </div>
      </Modal>

    </div>
  )
}

export default MyProductManagementScreen;
