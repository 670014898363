import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap';

function ItemCard(props) {
    const[quantity,setQuantity]=useState('');
    return (
        <div>
            <h1>{props.product.label}</h1>
            <img src={props.product.image?`data:image/jpeg;base64,${props.product.image}`:""} width="100%" height="400px"></img>
            <Col sm={12}>
              <input
                type="number"
                required
                value={quantity}
                name="quantity"
                onChange={(e)=>setQuantity(e.target.value)}
                className="form-control"
              />
            <Button variant="primary" onClick={(e)=>props.onClick(props.product.value,quantity)}>Add Item</Button>
            </Col>
        </div>
    )
}


export default ItemCard;