import React from "react";
import "bootstrap/js/src/collapse.js";
import { Container, Row, Col } from 'react-bootstrap';
import Style from "./css/bootstrap.min.css";

function MyNavBar(params) {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={2}>
            <a href={"/MyDashBoardScreen/"}>Dashboard</a>
          </Col>
          <Col sm={1}>
            <a href={"/MyCustomerManagementScreen/"}>Customers</a>
          </Col>
          <Col sm={2}>
            <a href={"/MyProductManagementScreen/"}>Products</a>
          </Col>
          <Col sm={2}>
            <a href={"/myOrdersScreen/"}>Order Management</a>
          </Col>
          <Col sm={1}>
            <a href={"/myMobileOrdersScreen/"}>Mobile Order</a>
          </Col>
          <Col sm={1}>
            <a href={"/MyOrderAndDetailListScreen/"}>Order Pool</a>
          </Col>
          <Col sm={1}>
            <a href={"/MySafeAndDeliveryTransactionReport/"}>Order Report</a>
          </Col>
          <Col sm={1}>
            <a href="/MyLoginScreen">Logout</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MyNavBar;
