import React from "react";
import "bootstrap/js/src/collapse.js";
import { Container, Row, Col } from 'react-bootstrap';
import Style from "./css/bootstrap.min.css";

function MyNavBar(params) {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={2}>
            <a href={"/MyDashBoardScreen/"}>Ana Rapor Ekranı</a>
          </Col>
          <Col sm={1}>
            <a href={"/MyCustomerManagementScreen/"}>Müşteri</a>
          </Col>
          <Col sm={2}>
            <a href={"/MyProductManagementScreen/"}>Ürün</a>
          </Col>
          <Col sm={2}>
            <a href={"/myOrdersScreen/"}>Sipariş Yönetimi</a>
          </Col>
          <Col sm={2}>
            <a href={"/MyOrderAndDetailListScreen/"}>Sipariş Havuzu</a>
          </Col>
          <Col sm={2}>
            <a href={"/MySafeAndDeliveryTransactionReport/"}>Fatura ve Teslimat Raporu</a>
          </Col>
          <Col sm={1}>
            <a href="/MyLoginScreen">Çıkış</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MyNavBar;
