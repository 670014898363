import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MyScreenFooter from "./myScreenFooter.js";
import Select from "react-select";
import MyNavBar from "./myNavBar.js";
import Modal from "react-modal";
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from './reports/myInvoicePrintOut'
import moment from "moment";
import ItemCard from '../../components/ItemCard.js'
import { ToastContainer, toast } from 'react-toastify';

let basurl = window.location.hostname;
let userName = localStorage.getItem("UserName");
let companyId = localStorage.getItem("CompanyID");
let operationCenterID = localStorage.getItem("OperationCenterID");


function MyMobileOrdersScreen(params) {
  const [customers, setCustomers] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [products, setProducts] = useState([]);
  const [customerId, setCustomerId] = useState(0);
  const [note, setNote] = useState("");
  const [orderDate, setOrderDate] = useState(moment().format("YYYY-MM-DD"));
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderTypeId, setOrderTypeId] = useState(1);
  const [productGroups,setProductGroups]=useState([]);
  const [filteredProducts,setFilteredProducts]=useState([])
  //Screen design for all order operations
  const [showOperationCompleted, setShowOperationCompleted] = useState(false);
  const [showInsertOrderDetailScreen, setShowInsertOrderDetailScreen] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [showInsertedOrderDetail, setShowInsertedOrderDetail] = useState(false);
  const [insertedOrderDetail, setInsertedOrderDetail] = useState();
  const [productGroupButtons,setProductGroupButtons]=useState();

  const handleCloseOperationCompleted = () => {
    setShowOperationCompleted(0);
    window.location.href = `/MyMobileOrdersScreen/`;
  };

  useEffect(() => {
    setIsMobileDevice(/Mobi/.test(navigator.userAgent));

    const url = `http://${basurl}:8081/getCustomersAPI`;
    axios
      .get(url, {
        params: {
          UserName: userName,
          CompanyId: companyId,
        },
      })
      .then((res) => {
        const customers = res.data;
        setCustomers(customers);
      });

    const url1 = `http://${basurl}:8081/getProductsAPI`;
    axios
      .get(url1, {
        params: {
          UserName: userName,
          CompanyId: companyId,
        },
      })
      .then((res1) => {
        const products = res1.data;
        console.log("products");
        console.log(products);
        setProducts(products);

        setFilteredProducts(products)
        products.map(product=>{
          let index=productGroups.findIndex(pg=>pg.value===product.ProductGroupID)
          if(index===-1){
            productGroups.push({
              value:product.ProductGroupID,
              label:product.ProductGroup
            })
          }
        })
        setProductGroups(productGroups);
      });

    const url2 = `http://${basurl}:8081/getOrderTypesAPI`;
    axios
      .get(url2, {
        params: {
          UserName: userName,
          CompanyId: companyId,
        },
      })
      .then((res2) => {
        setOrderTypes(res2.data);
      });
  }, []);

  const handleProductGroupSelection=(key)=>{
    let result=products.filter(p=>p.ProductGroupID===key)
    setFilteredProducts(result);
  }

  function handleSubmit(event) {
    event.preventDefault();
    fetch(`http://${basurl}:8081/myOrderImportAPI`, {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserName: userName,
        CompanyId: companyId
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data === 1) {
        }
      })
      .catch((err) => console.log(err.response.data));
  }

  //select combobox design. it is important
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "35px",
      height: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  };

  const handleDeleteOperations = (e) => {
    setOrderDetails(orderDetails.filter((b) => b.Id != e));
  };

  const handleInsertDetailOperation = () => { setShowInsertOrderDetailScreen(!showInsertOrderDetailScreen) };

  const componentRef = useRef();
  const handleInvoicePrintOutOperation = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => setShowOperationCompleted(1)
  });


  function handleOrderAndDetailImport(e) {
    if(!customerId){
      toast.error(`Customer is not selected. Please select customer and try again!`);
      return
    }
    fetch(`http://${basurl}:8081/myOrderAndDetailImportAPI`, {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserName: userName,
        CompanyId: companyId,
        CustomerId: customerId,
        OrderDate: orderDate,
        ExpectedDeliveryDate: expectedDeliveryDate,
        Note: note,
        OrderDetails: orderDetails,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        let insertResult = data;
        if (insertResult === 1) {
          window.location.href = `/MyOrdersScreen/`;
        }
      })
      .catch((err) => console.log(err.response.data));
  }

  const handleOnChange=(key,value)=>{
    let index=orderDetails.findIndex((e)=>e.productId===key);
    if(index!==-1){
      orderDetails.splice(index,1)
    }

    orderDetails.push({
      Id: orderDetails.length === 0 ? 1 : Math.max.apply(Math, orderDetails.map(function (o) { return o.Id + 1; })),
      productId: key,
      Product: products.find((e) => e.value === parseInt(key)).label,
      UnitSalesPrice: products.find((e) => e.value === parseInt(key)).SalesPrice,
      Quantity: parseFloat(value),
      unitId: products.find((e) => e.value === parseInt(key)).UnitID,
      unit: products.find((e) => e.value === parseInt(key)).Unit
    });
  }

useEffect(()=>{
  setInsertedOrderDetail(showInsertedOrderDetail?
    <Container fluid className="border border-info" >
    &nbsp;
    <Row style={{marginLeft:0, marginRight:0, paddingLeft:0, paddingRight:0}}>
      <Col sm={12} style={{paddingLeft:0, paddingRight:0}}>
        <table>
          <thead>
            <tr style={{fontSize:"9px"}}>
              <th><input type="checkbox" name="masterCheckbox" /></th>
              <th>Order</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Unit Price</th>
              <th>Total Price</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody className="show-grid">
            {orderDetails.map((e, index) =>
              <tr className="tableRow" key={e.Id}>
                <td><input type="checkbox" name="Id" />&nbsp;</td>
                <td>{e.Id}</td>
                <td style={{maxWidth:"20%",fontSize:"10px"}}>{e.Product}</td>
                <td>{e.Quantity}</td>
                <td>{e.unit}</td>
                <td>{e.UnitSalesPrice}</td>
                <td style={{ backgroundColor: "#a8dda8", color: "#2d6187", textAlign: "center", fontWeight: "bolder", fontSize: "15px" }}>{parseFloat(e.UnitSalesPrice * e.Quantity).toFixed(2)}</td>
                <td><Button className="btn btn-info" style={{ minHeight: "20px", fontWeight: "bolder", color: "black" }} onClick={() => handleDeleteOperations(e.Id)}><span>Delete</span></Button></td>
              </tr>)}
          </tbody>
        </table>
      </Col>
    </Row>
  &nbsp;&nbsp;
  </Container>:"")
},[showInsertedOrderDetail,orderDetails])

  return (
    <div className="App">
      <ToastContainer draggable pauseOnHover newestOnTop={true} position="top-center" />
      {
        showHeaderMenu?
        <div className="Form1">
          <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
        </div>:""
      }

      <div hidden>
        <ComponentToPrint ref={componentRef}
          customer={customerId != 0 ? customers.find((e) => e.value === customerId).label : ""}
          expectedDeliveryDate={expectedDeliveryDate}
          orderDate={orderDate}
          note={note}
          orderDetails={orderDetails}
        />
      </div>

      <form onSubmit={handleSubmit} className="Form2">
        <Container fluid className="border border-warning">
          <Row>
            <Col sm={12} md={6}>
              <Select name="customerId" value={customers.find((e) => e.value === parseInt(customerId))} placeholder="Select Customer" styles={customStyles} onChange={e => setCustomerId(e.value)} options={customers} />
            </Col>
            {isMobileDevice?<div>&nbsp;</div>:""}
            <Col sm={12} md={6}>
              <Select name="orderTypeId" value={orderTypes.find((e) => e.value === parseInt(orderTypeId))} placeholder="Select Order Type" styles={customStyles} onChange={e => setOrderTypeId(e.value)} options={orderTypes} />
            </Col>
          </Row>
        &nbsp;&nbsp;
          <Row>
            <Col sm={6}>
              <label>Order Date: </label>
              <input
                type="date"
                required
                value={orderDate}
                name="orderDate"
                onChange={(e) => setOrderDate(e.target.value)}
                className="form-control"
              />
            </Col>
            <Col sm={6}>
              <label>Expected Delivery Date: </label>
              <input
                type="date"
                required
                value={expectedDeliveryDate}
                name="expectedDeliveryDate"
                onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <textarea
                rows="3"
                placeholder="If you have extra information, please fill here!"
                name="note"
                onChange={(e) => setNote(e.target.value)}
                className="form-control"
              />
            </Col>
          </Row>
        </Container>
        &nbsp;
        <Container fluid className="">
          <Row>
            <Col sm={3}>
              <button
                type="button"
                value="button"
                className="btn btn-info"
                onClick={handleInsertDetailOperation}
              > Add Product
        </button>
            </Col>
            <Col sm={3}>
              <button
                type="button"
                value="button"
                className="btn btn-dark"
                onClick={()=>setShowInsertedOrderDetail(!showInsertedOrderDetail)}
              > {showInsertedOrderDetail?'Hide Detail':'Show Detail'}
        </button>
            </Col>
            <Col sm={3}>
              <button
                type="button"
                value="button"
                className="btn btn-warning"
                onClick={handleOrderAndDetailImport}
              > Save
        </button>
            </Col>
            <Col sm={3}>
              <button
                type="button"
                value="button"
                className="btn btn-primary"
                onClick={()=>setShowHeaderMenu(!showHeaderMenu)}
              > {showHeaderMenu?'Hide Header Menu':'Show Header Menu'}
        </button>
            </Col>
          </Row>
        </Container>
        {showInsertOrderDetailScreen?(productGroups?
        <Container fluid className="mt-2">
          <Row>{productGroups.map(pg=>
            <Col key={pg.value} sm={4}>
              <button
                type="button"
                value="button"
                className="btn btn-info"
                onClick={()=>handleProductGroupSelection(pg.value)}
                > {pg.label}
              </button>
            </Col>)}
          </Row>
        </Container>:""):""}
        &nbsp;
        {insertedOrderDetail}
        
        <Container fluid className="border border-info">
          &nbsp;
              {showInsertOrderDetailScreen?
          <Row>
                {filteredProducts.map((product)=>
                <Col key={product.value} xs={12} md={6}>
                <ItemCard
                product={product}
                onClick={handleOnChange}
                ></ItemCard>
                </Col>
                )}
          </Row>:""
              }
        </Container>
      </form>
      &nbsp;&nbsp;

      <div className="Form1">
        <MyScreenFooter
          screenName={"Order Management Screen"}
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>

      <Modal
        isOpen={showOperationCompleted}
        onRequestClose={() => setShowOperationCompleted(false)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "45%" }
        }}
      >
        <h2>Info!</h2>
        <br></br>
        <p>Operation completed.You can check order from "Order Pool" screen</p>
        <div>
          <button value="setShowOperationCompleted" className="btn btn-success" onClick={handleCloseOperationCompleted}>Close Screen</button>
        </div>
      </Modal>
    </div >
  );
}

export default MyMobileOrdersScreen;
