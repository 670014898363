import React, { useEffect } from "react";
import style from "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import MyLoginScreen from "./invoice4me/en/myLoginScreen.js";
import MyLoginScreen_TR from "./invoice4me/tr/myLoginScreen.js";
import MyDashBoardScreen from "./invoice4me/en/myDashBoardScreen.js";
import MyDashBoardScreen_TR from "./invoice4me/tr/myDashBoardScreen.js";
import MyOrdersScreen from "./invoice4me/en/myOrdersScreen.js";
import MyMobileOrdersScreen from "./invoice4me/en/myMobileOrdersScreen.js";
import MyOrdersScreen_TR from "./invoice4me/tr/myOrdersScreen.js";
import MyOrderAndDetailListScreen from "./invoice4me/en/myOrderAndDetailListScreen.js";
import MyOrderAndDetailListScreen_TR from "./invoice4me/tr/myOrderAndDetailListScreen.js";
//Master data
import MyCustomerManagementScreen from "./invoice4me/en/masterData/myCustomerManagementScreen.js";
import MyCustomerManagementScreen_TR from "./invoice4me/tr/masterData/myCustomerManagementScreen.js";
import MyProductManagementScreen from "./invoice4me/en/masterData/myProductManagementScreen.js";
import MyProductManagementScreen_TR from "./invoice4me/tr/masterData/myProductManagementScreen.js";

//reports
import MySafeAndDeliveryTransactionReport from "./invoice4me/en/reports/mySafeAndDeliveryTransactionReport.js";
import MySafeAndDeliveryTransactionReport_TR from "./invoice4me/tr/reports/mySafeAndDeliveryTransactionReport.js";

import { Route, BrowserRouter as Router, Switch, BrowserRouter } from "react-router-dom";

const userLangugae = window.navigator.language === "tr" ? "tr" : "en";
// console.log(window.navigator);

function App() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `We are here to help you.`;
  }, []);
  console.log(userLangugae);
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path="/" exact component={userLangugae === "tr" ? MyLoginScreen_TR : MyLoginScreen} />
          <Route path="/MyLoginScreen" component={userLangugae === "tr" ? MyLoginScreen_TR : MyLoginScreen} />
          <Route path="/MyDashBoardScreen/" exact component={userLangugae === "tr" ? MyDashBoardScreen_TR : MyDashBoardScreen} />
          <Route path="/MyCustomerManagementScreen/" exact component={userLangugae === "tr" ? MyCustomerManagementScreen_TR : MyCustomerManagementScreen} />
          <Route path="/MyProductManagementScreen/" exact component={userLangugae === "tr" ? MyProductManagementScreen_TR : MyProductManagementScreen} />
          <Route path="/MyOrdersScreen/" component={userLangugae === "tr" ? MyOrdersScreen_TR : MyOrdersScreen} />
          <Route path="/myMobileOrdersScreen/" component={userLangugae === "tr" ? MyMobileOrdersScreen : MyMobileOrdersScreen} />
          <Route path="/MyOrderAndDetailListScreen/" component={userLangugae === "tr" ? MyOrderAndDetailListScreen_TR : MyOrderAndDetailListScreen} />
          <Route path="/MySafeAndDeliveryTransactionReport/" component={userLangugae === "tr" ? MySafeAndDeliveryTransactionReport_TR : MySafeAndDeliveryTransactionReport} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App; //exporting a component make it reusable and this is the beauty of react
