//post data
import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import Style from './App.css'
import Logo from '../imgs/nareppo.png'
import MD5 from "crypto-js/md5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyLoginScreen(params) {
  const [userName, setUserName] = useState();
  const [passWord, setPassWord] = useState();
  const [apiResult, setApiResult] = useState(99);

  function getMyCredential(event) {
    event.preventDefault();
    let basurl = window.location.hostname
    fetch(`http://${basurl}:8081/myLoginCredential`, {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userName: userName,
        passWord: MD5(passWord).toString()
      })
    })
      .then(res => res.json())
      .then(
        data => {
          if (data[0].Company != 0) {
            window.location.href = `/MyDashBoardScreen/`;
            localStorage.setItem("CompanyID", data[0].CompanyID);
            localStorage.setItem("UserName", userName);
            localStorage.setItem("UserTypeID", data[0].UserTypeID);
            localStorage.setItem("OperationCenterID", data[0].OperationCenterID);
            localStorage.setItem("FullName", data[0].FullName);
            localStorage.setItem("Address", data[0].Address);
            localStorage.setItem("TaxCode", data[0].TaxCode);
            localStorage.setItem("Mobile", data[0].Mobile);
            localStorage.setItem("Email", data[0].Email);
            localStorage.setItem("FreeText1", data[0].FreeText1);
            localStorage.setItem("FreeText2", data[0].FreeText2);
            localStorage.setItem("FreeText3", data[0].FreeText3);
          } else {
            toast.error(`Kullanıcı adı ya da şifre hatalı. Kontrol edip lütfen tekrar deneyin!`);
          }
        }

      )
      .catch(err => console.log(err.response.data));
  }
  return (
    <div clasName="FormLogin">
      <ToastContainer draggable pauseOnHover newestOnTop={true} position="top-center" />
      <div className="bg-img">
        <div class="bird-container bird-container--one">
          <div class="bird bird--one"></div>
        </div>
        <div class="bird-container bird-container--two">
          <div class="bird bird--two"></div>
        </div>
        <div class="bird-container bird-container--three">
          <div class="bird bird--three"></div>
        </div>
        <div class="bird-container bird-container--four">
          <div class="bird bird--four"></div>
        </div>

        <form className="container">
          <div class="clearfix float-my-children">
            <img src={Logo} style={{ height: "100px", width: "100px" }} />
          </div><br></br>
          <input type="text" name="userName" onChange={e => setUserName(e.target.value)} placeHolder="E-mail" required /> <br></br>
          <input type="password" name="passWord" onChange={e => setPassWord(e.target.value)} placeHolder="Password" required></input> <br></br>
          <button type="submit" onClick={getMyCredential} value="Submit" className="btn">Login</button>
        </form>
      </div>
    </div>
  )
}

export default MyLoginScreen;