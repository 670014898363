import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import style from "./App.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MyNavBar from "./myNavBar.js";
import MyScreenFooter from "./myScreenFooter.js";
import Modal from "react-modal";
import moment from "moment";
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import InvoicePrintOut from './reports/myInvoicePrintOut'
import PickListToPrint from './reports/myPickListPrintOut'
import DeliveryNotePrintOut from './reports/myDeliveryNotePrintOut'

let basurl = window.location.hostname;
let userName = localStorage.getItem("userName");
let companyId = localStorage.getItem("CompanyID");
let operationCenterID = localStorage.getItem("OperationCenterID");

function MyOrderAndDetailListScreen(params) {
  const [orders, setOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState({})
  const [searchValue, setSearchValue] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [pickList, setPickList] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const [totalSelQuantity, setTotalSelQuantity] = useState(0);
  //for delivery form information
  const [receivedBy, setRecievedBy] = useState("");
  const [receivedAmount, setRecievedAmount] = useState(0.00);
  const [deliveryDateTime, setDeliveryDateTime] = useState(moment().format("YYYY-MM-DD"));
  const [deliveryNote, setDeliveryNote] = useState("");
  //For order cancellation
  const [selectionWarning, setSelectionWarning] = useState(0);
  const [selectedMorethanOneWarning, setSelectedMorethanOneWarning] = useState(0);
  const [showCancelReasonForm, setShowCancelReasonForm] = useState(0);
  const [showDeliveryForm, setShowDeliveryForm] = useState(false);

  const [beginDate, setBeginDate] = useState(moment().subtract(365, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));

  // For getting search result report. İt will be useful
  const [filteredTotalQuantity, setFilteredTotalQuantity] = useState(0)
  const [filteredTotalLines, setFilteredTotalLines] = useState(0);

  //decision for delivery note
  const [printForSubCompany, setPrintForSubCompany] = useState(false);
  const [showSelectionBeforeDeliveryNote, setShowSelectionBeforeDeliveryNote] = useState(false);

  const handleDeliveryOperation = (e) => {
    setSelectedOrderId(e);
    setSelectedOrder(orders.find((b) => b.Id == e));
    setShowDeliveryForm(true);
  }

  const handleInvoicePrintOutOperation = (e) => {
    setSelectedOrderId(e);
    setSelectedOrder(orders.find((b) => b.Id == e));
    handlePrint();
  }

  const handleDeliveryNotePrintOutOperation = (e) => {
    setSelectedOrderId(e);
    setSelectedOrder(orders.find((b) => b.Id == e));
    setShowSelectionBeforeDeliveryNote(true);
    //   handlePrintDeliveryNote();
  }

  //Screen design for all order operations
  const [showOperationCompleted, setShowOperationCompleted] = useState(false)

  const closeSelectionWarningModal = () => setSelectionWarning(0);
  const closeSelectedMorethanOneWarning = () => setSelectedMorethanOneWarning(0);
  const [cancelReason, setCancelReason] = useState("");
  const submitCancelReasonForm = () => { };
  function compare(a, b) {
    const pkA = a.AddressText;
    const pkB = b.AddressText;

    const pk2A = a.Customer;
    const pk2B = b.Customer;

    let comparison = 0;

    if (pkA > pkB) {
      comparison = 1;
    } else if (pkA < pkB) {
      comparison = -1;
    } else if (pkA === pkB) {
      if (pk2A > pk2B) {
        comparison = 2;
      } else if (pk2A < pk2B) {
        comparison = -2;
      }
    }
    return comparison;
  }

  useEffect(() => {
    const url = `http://${basurl}:8081/myOrderAndDetailListScreenAPI`;
    axios
      .get(url, {
        params: {
          UserName: userName,
          CompanyId: companyId,
          OperationCenterID: operationCenterID
        },
      })
      .then((res) => {
        const orders = res.data.sort(res.data.Id);
        //we did the sorting
        setOrders(orders.sort(compare));
        orders.forEach((order) => (order["selected"] = 0));
        setFilteredOrders(orders);
        setSelectedOrder(orders[0]);
      });
  }, []);

  //filter with the search word 
  useEffect(() => {
    setFilteredOrders(orders.filter((e) => (e.OrderCode + e.Customer + e.OrderStatus + e.OrderType + e.CustomerType).toLowerCase().indexOf(searchValue) > -1));
    setPickList([]);
  }, [searchValue]);

  const handlePickListPrintOutOperation = () => {
    handlePickListPrint();

  };

  useEffect(() => {
    filteredOrders.map(order => {
      setOrderDetails([]);
      order && order.length && order.OrderDetails.map(det => {
        orderDetails.push({
          ProductCode: det.ProductCode,
          Product: det.Product,
          Unit: det.Unit,
          Quantity: det.Quantity
        });
      });
    });

    orderDetails && orderDetails.length && orderDetails.reduce(function (res, value) {
      if (!res[value.ProductCode]) {
        res[value.ProductCode] = { ProductCode: value.ProductCode, Unit: value.Unit, Product: value.Product, Quantity: 0 };
        pickList.push(res[value.ProductCode])
      }
      res[value.ProductCode].Quantity += value.Quantity;
      return res;
    }, {});
  }, [filteredOrders]);

  //submit delivery information
  const submitDeliveryForm = () => {
    fetch(`http://${basurl}:8081/myOrderDeliveryAPI`, {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserName: userName,
        CompanyId: companyId,
        OperationCenterID: operationCenterID,
        OrderID: selectedOrderId,
        ReceivedBy: receivedBy,
        ExpectedAmount: selectedOrder.Amount,
        ReceivedAmount: receivedAmount,
        DeliveryDateTime: deliveryDateTime,
        DeliveryNote: deliveryNote
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data === 1) {
          setShowDeliveryForm(false);
          window.location.href = `/MyOrderAndDetailListScreen/`;
        }
      })
      .catch((err) => console.log(err.response.data));
  }

  const componentRefInvoice = useRef();
  const componentRefPickList = useRef();
  const componentRefDeliveryNote = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRefInvoice.current,
    onAfterPrint: () => setShowOperationCompleted(1)
  });

  const handlePickListPrint = useReactToPrint({
    content: () => componentRefPickList.current,
    onAfterPrint: () => setShowOperationCompleted(1)
  });
  // console.log(filteredOrders);
  const handlePrintDeliveryNote = useReactToPrint({
    onBeforePrint: () => setShowSelectionBeforeDeliveryNote(false),
    content: () => componentRefDeliveryNote.current,
    onAfterPrint: () => setShowOperationCompleted(1)
  });


  return (
    <div className="master">
      <div className="MasterTitle"></div>
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>

      {selectedOrder&&selectedOrder.OrderDetails ? <div hidden>
        <InvoicePrintOut ref={componentRefInvoice}
          customer={selectedOrder.Customer}
          invoiceNumber={selectedOrder.Id}
          customerAddress={selectedOrder.CustomerAddress}
          custPostCode={selectedOrder.CustPostCode}
          custCompanyNumber={selectedOrder.CustCompanyNumber}
          expectedDeliveryDate={selectedOrder.ExpectedDeliveryDate}
          orderDate={selectedOrder.OrderDate}
          note={selectedOrder.Notes}
          orderDetails={selectedOrder.OrderDetails}
          customerBalance={selectedOrder.CustomerBalance}
          orderTotalValue={selectedOrder.OrderTotalValue}
        />
      </div> : ""}

      {selectedOrder&&selectedOrder.OrderDetails ? <div hidden>
        <DeliveryNotePrintOut ref={componentRefDeliveryNote}
          customer={selectedOrder.Customer}
          customerAddress={selectedOrder.CustomerAddress}
          custPostCode={selectedOrder.CustPostCode}
          custCompanyNumber={selectedOrder.CustCompanyNumber}
          expectedDeliveryDate={selectedOrder.ExpectedDeliveryDate}
          orderDate={selectedOrder.OrderDate}
          note={selectedOrder.Notes}
          orderDetails={selectedOrder.OrderDetails}
          customerBalance={selectedOrder.CustomerBalance}
          orderTotalValue={selectedOrder.OrderTotalValue}
          printForSubCompany={printForSubCompany}
        />
      </div> : ""}

      {filteredOrders.length ? <div hidden>
        <PickListToPrint ref={componentRefPickList}
          PickList={pickList}
        />
      </div> : ""}

      <div className="Form1">
        <Container fluid>
          <Row>
            <Col sm={10}>
              <input type="text" placeholder="Write here your search text(Customer, Sender,Phones)..." required name="code" value={searchValue} onChange={e => setSearchValue(e.target.value)} className="form-control" />
            </Col>
            <Col sm={2}>
              <Button className="btn btn-success" style={{ minHeight: "50px", fontWeight: "bolder", color: "black" }} onClick={handlePickListPrintOutOperation}><span>Pick List</span></Button>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col>
              <table>
                <thead>
                  <tr>
                    <th><input type="checkbox" name="masterCheckbox" /></th>
                    <th>Order Code</th>
                    <th>Order Type</th>
                    <th style={{minWidth:"80px"}}>Order Date</th>
                    <th>Expected Delivery Date</th>
                    <th>Customer</th>
                    <th>Customer Type</th>
                    <th>Note</th>
                    <th>Unit Price</th>
                    <th>Previous Balance</th>
                    <th>Order Value</th>
                    <th>Invoice</th>
                    <th>Delivery Note</th>
                    <th>Delivery</th>
                  </tr>
                </thead>
                <tbody className="show-grid">
                  {filteredOrders.sort((a,b)=>a.Id<b.Id?1:-1)
                  .map((e, index) => <tr className="tableRow" key={e.Id}>
                    <td><input type="checkbox" name="Id" />&nbsp;</td>
                    <td>{e.OrderCode}</td>
                    <td>{e.OrderType}</td>
                    <td>{e.OrderDate}</td>
                    <td>{e.ExpectedDeliveryDate}</td>
                    <td>{e.Customer}</td>
                    <td>{e.CustomerType}</td>
                    <td>{e.Notes}</td>
                    <td>{e.OrderDetails.map(det => `${det.Product} ${det.Quantity} ${det.UnitSalesPrice} \n`)}</td>
                    <td>{e.CustomerBalance}</td>
                    <td>{e.OrderTotalValue}</td>
                    <td><Button className="btn btn-info" style={{ minHeight: "50px", fontWeight: "bolder", maxWidth: "80px", color: "black" }} onClick={() => handleInvoicePrintOutOperation(e.Id)}><span>Invoice</span></Button></td>
                    <td><Button className="btn btn-dark" style={{ minHeight: "50px", fontWeight: "bolder", color: "black", layout: "fixed", maxWidth: "80px", whiteSpace: "normal" }} onClick={() => handleDeliveryNotePrintOutOperation(e.Id)}><span>Delivery Note</span></Button></td>
                    <td><Button className="btn btn-warning" style={{ minHeight: "50px", fontWeight: "bolder", maxWidth: "80px", color: "black" }} onClick={() => handleDeliveryOperation(e.Id)}><span>Delivery</span></Button></td>

                  </tr>)}

                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={showDeliveryForm}
        onRequestClose={() => setShowDeliveryForm(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "black", height: "85%" },
        }}
      >
        <Container fluid className="border border-primary">
          <Row>
            <Col sm={6}>
              <h2>Customer</h2>
            </Col>
            <Col sm={6}>
              <h2>Order Detail</h2>
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-primary">
          <Row>
            <Col sm={6}>
              <h2>{selectedOrder&&selectedOrder.Customer}</h2>
            </Col>
            <Col sm={6}>
              <h2>{selectedOrder&&selectedOrder.OrderDetails ? selectedOrder.OrderDetails.map(det => `${det.Product} ${det.Quantity} ${det.UnitSalesPrice} \n`) : ""}</h2>
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-primary">
          <Row>
            <Col sm={12}>
              <h2> Note: {selectedOrder&&selectedOrder.Notes}</h2>
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-primary">
          <Row>
            <Col sm={12}>
              <input
                type="text"
                placeholder="Teslimat noktasında ekstra notunuz varsa buraya giriniz!"
                required
                name="deliveryNote"
                onChange={(e) => setDeliveryNote(e.target.value)}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <input
                type="text"
                placeholder="Teslim Alan"
                required
                name="ReceivedBy"
                onChange={(e) => setRecievedBy(e.target.value)}
                className="form-control"
              />
            </Col>
            <Col sm={6}>
              <input
                type="date"
                defaultValue={deliveryDateTime}
                required
                name="DeliveryDateTime"
                onChange={(e) => setDeliveryDateTime(e.target.value)}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <label style={{ fontWeight: "bolder", fontSize: "25px" }}>Received Amount :</label>
            </Col>
            <Col sm={6}>
              <input
                type="number"
                value={receivedAmount}
                required
                name="receivedAmount"
                onChange={(e) => setRecievedAmount(e.target.value)}
                className="form-control"
              />
            </Col>
          </Row>
        </Container>&nbsp;
        <Container fluid className="border border-warning">
          <Row>
            <Col sm={6}>
              <label style={{ fontWeight: "bolder", fontSize: "25px" }}>Customer Balance:</label>
            </Col>
            <Col sm={6}>
              <label style={{ fontWeight: "bolder", fontSize: "25px", backgroundColor: "yellow", color: "red" }}>{(Number(selectedOrder&&selectedOrder.CustomerBalance) + Number(selectedOrder&&selectedOrder.OrderTotalValue)).toFixed(2)}</label>
            </Col>
          </Row>
        </Container>&nbsp;
        <div>
          <button
            value="submitDeliveryForm"
            className="btn btn-success"
            onClick={submitDeliveryForm}
          >
            Save
          </button>
        </div>
      </Modal >

      <Modal
        isOpen={showSelectionBeforeDeliveryNote}
        onRequestClose={() => { setShowSelectionBeforeDeliveryNote(false); setPrintForSubCompany(false) }
        }
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "black", height: "30%" },
        }}
      >
        <Container fluid className="border border-primary">
          <Row>
            <Col sm={6}>
              <h2>Print For Subcompany?</h2>
            </Col>
            <Col sm={6}>
              <input
                style={{ minWidth: "30px", minHeight: "30px", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                type="checkbox"
                checked={printForSubCompany}
                name="printForSubCompany"
                onChange={(e) => setPrintForSubCompany(e.target.checked)}
              />
            </Col>
          </Row>
        </Container>
        &nbsp;
        <div>
          <button
            value="handlePrintDeliveryNote"
            className="btn btn-success"
            onClick={handlePrintDeliveryNote}
          >
            Print
          </button>
        </div>
      </Modal >

      <div className="Form1">
        <MyScreenFooter
          screenName="Order Pool"
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>
    </div >
  );
}

export default MyOrderAndDetailListScreen;