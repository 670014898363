import React, { useState, useEffect, useRef } from 'react'
import axios, { post } from 'axios';
import MyNavBar from '../myNavBar.js'
import MyScreenFooter from '../myScreenFooter.js'
import Style from '../App.css'
import Modal from 'react-modal'
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Container, Row, Col } from 'react-bootstrap';

function MyCustomerManagementScreen(params) {
  const [cities, setCities] = useState([])
  const [towns, setTowns] = useState([])
  const [customerTypes, setCustomerTypes] = useState([])
  const [townsByCity, setTownsByCity] = useState([])

  const [cityID, setCityID] = useState(0);
  const [townID, setTownID] = useState(0);
  const [customerTypeId, setCustomerTypeId] = useState(0);

  const [code, setCode] = useState('')
  const [fullName, setFullName] = useState('')
  const [taxCode, setTaxCode] = useState('')
  const [taxOffice, setTaxOffice] = useState('')
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [freeText1, setFreeText1] = useState('')
  const [postCode, setPostCode] = useState('')
  const [active, setActive] = useState(true)

  //edit and search option
  const [selected, setSelected] = useState(0)
  const [selectedCustomerId, setSelectedCustomerId] = useState(0)
  const [edit, setEdit] = useState(0)
  const [customerList, setCustomerList] = useState([])
  const [warningMessage, setWarningMessage] = useState("")
  const [openWarning, setOpenWarning] = useState(0)
  const closeWarningModal = () => setOpenWarning(0);

  let basurl = window.location.hostname;
  let userName = localStorage.getItem("UserName");
  let companyId = localStorage.getItem("CompanyID");

  useEffect(() => {
    const url1 = `http://${basurl}:8081/getCitiesAPI`
    axios.get(url1, {
      params: {
        userName: userName,
        companyId: companyId
      }
    })
      .then(res1 => {
        const cities = res1.data;
        setCities(cities)
      });

    const url2 = `http://${basurl}:8081/getTownsAPI`
    axios.get(url2, {
      params: {
        userName: userName,
        companyId: companyId
      }
    })
      .then(res2 => {
        const towns = res2.data;
        setTowns(towns)
      });
    const url3 = `http://${basurl}:8081/getCustomerTypesAPI`
    axios.get(url3, {
      params: {
        userName: userName,
        companyId: companyId
      }
    })
      .then(res3 => {
        setCustomerTypes(res3.data);
      });
  }, [])

  useEffect(() => {
    setTownsByCity(towns.filter(e => e.CityID === parseInt(cityID)))
  }, [cityID])

  function handleSubmit(event) {
    event.preventDefault();
    let basurl = window.location.hostname
    fetch(`http://${basurl}:8081/myCustomerImportAPI`, {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        UserName: userName,
        CompanyId: companyId,
        Code: code,
        FullName: fullName,
        TaxCode: taxCode,
        TaxOffice: "",
        Address: address,
        PostCode: postCode,
        CityID: 1000, // For uk we only use London. All things from address and post code
        TownID: 1000, // For uk we only use London. All things from address and post code
        Email: email,
        Mobile: mobile,
        FreeText1: freeText1,
        CustomerTypeId: customerTypeId,
        Operation: edit,
        EditedCustomerId: selectedCustomerId,
        IsActive: active ? 1 : 0
      })
    })
      .then(res => res.json())
      .then(data => {
        let insertResult = data
        if (insertResult === 1) {
          window.location.href = `/myCustomerManagementScreen/`;
        }
      })
      .catch(err => console.log(err));
  }

  function compare(a, b) {
    const pkA = a.Id;
    const pkB = b.Id;

    let comparison = 0;

    if (pkA > pkB) {
      comparison = -1;
    } else if (pkA < pkB) {
      comparison = 1;
    }
    return comparison;
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '35px',
      height: '35px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '35px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '35px',
    }),
  };

  function getAllCustomerList(event) {
    const url = `http://${basurl}:8081/getAllCustomerList`
    axios.get(url, {
      params: {
        UserName: userName,
        CompanyId: companyId,
        Code: code,
        FullName: fullName//adress description
      }
    })
      .then(res => {
        const customerList = res.data.sort(res.data.Id);
        setCustomerList(customerList.sort(compare));
        customerList.forEach(customer => customer["selected"] = 0)
        // console.log(res.data);
      });
    renderTableData();
  }

  function handleEditClick() {
    //console.log(productList.filter(product=>product["selected"]===1));
    if (customerList.filter(customer => customer["selected"] === 1).length === 0) {
      setWarningMessage("Herhangi bir müşteri seçilmemiş. Lütfen seçip tekrar deneyin.")
      setOpenWarning(1)
    }
    if (customerList.filter(customer => customer["selected"] === 1).length > 1) {
      setWarningMessage("Birden fazla müşteri seçilmiş. Lütfen kontrol edip tekrar deneyin.")
      setOpenWarning(1)
    }
    if (customerList.filter(customer => customer["selected"] === 1).length === 1) {
      //console.log(productList.filter(product=>product["selected"]===1));
      setSelectedCustomerId((customerList.filter(customer => customer["selected"] === 1))[0]["Id"])
      setCode((customerList.filter(customer => customer["selected"] === 1))[0]["Code"]);
      setFullName((customerList.filter(customer => customer["selected"] === 1))[0]["FullName"]);
      setCustomerTypeId((customerList.filter(customer => customer["selected"] === 1))[0]["CustomerTypeID"]);
      setTaxCode((customerList.filter(customer => customer["selected"] === 1))[0]["taxCode"]);
      setTaxOffice((customerList.filter(customer => customer["selected"] === 1))[0]["taxOffice"]);
      setAddress((customerList.filter(customer => customer["selected"] === 1))[0]["Address"]);
      setPostCode((customerList.filter(customer => customer["selected"] === 1))[0]["PostCode"]);

      setFreeText1((customerList.filter(customer => customer["selected"] === 1))[0]["FreeText1"]);

      setEmail((customerList.filter(customer => customer["selected"] === 1))[0]["Email"] ? (customerList.filter(customer => customer["selected"] === 1))[0]["Email"] : "")
      setMobile((customerList.filter(customer => customer["selected"] === 1))[0]["Mobile"] ? (customerList.filter(customer => customer["selected"] === 1))[0]["Mobile"] : "")
      setActive((customerList.filter(customer => customer["selected"] === 1))[0]["IsActive"])
      setEdit(1)
    }
  };
  // console.log("active");
  // console.log(active);
  function handleSelection(e) {
    let selectedID = e.target.value
    customerList.map((customer) => {
      if (customer["Id"] === parseInt(selectedID) & customer["selected"] === 0) {
        customer["selected"] = 1
      } else if (customer["Id"] === parseInt(selectedID) & customer["selected"] === 1) {
        customer["selected"] = 0
      }
    });
    //setSelectionWarning(selectedOrders.length>0?1:0)
  }

  function renderTableData() {
    return customerList.map((customer, index) => {
      const { Id, Code, FullName, taxCode, taxOffice, Address, PostCode, Email, Mobile, FreeText1, EnteredBy, CreateDate, IsActive, CustomerType } = customer //destructuring
      return (
        <tr key={Id}>
          <td><input type="checkbox" selected={selected} onChange={handleSelection} value={Id} name="Id" />&nbsp;</td>
          <td>{Id}</td>
          <td>{Code}</td>
          <td>{FullName}</td>
          <td>{CustomerType}</td>
          <td>{taxCode}</td>
          <td>{taxOffice}</td>
          <td>{Address}</td>
          <td>{PostCode}</td>
          <td>{Email}</td>
          <td>{Mobile}</td>
          <td>{IsActive}</td>
          <td>{FreeText1}</td>
          <td>{EnteredBy}</td>
          <td>{CreateDate}</td>
        </tr>
      )
    })
  }

  return (
    <div>
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>
      <form className="Form2">
        <Container fluid className="border border-info">
          <Row>
            <Col sm={4}>
              <input type="text" value={code} placeHolder="Müşteri Kodunu giriniz" required name="code" onChange={e => setCode(e.target.value)} className="form-control" />
            </Col>
            <Col sm={8}>
              <input type="text" value={fullName} placeHolder="Müşteri Adını giriniz" required name="fullName" onChange={e => setFullName(e.target.value)} className="form-control" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <input type="text" value={taxCode} placeHolder="Company House Number" required name="taxCode" onChange={e => setTaxCode(e.target.value)} className="form-control" />
            </Col>
            <Col sm={6}>
              <select name="customerTypeId" required value={customerTypeId} onChange={e => setCustomerTypeId(e.target.value)} className="form-control">
                <option value="0" disabled selected>Müşteri Tipi Seçiniz...</option>
                {customerTypes.map(data => <option key={data.ID} value={data.ID}>{data.Name}</option>)}</select>
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-success">
          <Row>
            <Col sm={10}>
              <input type="text" value={address} placeHolder="Adresi giriniz" required name="address" onChange={e => setAddress(e.target.value)} className="form-control" />
            </Col>
            <Col sm={2}>
              <input type="text" value={postCode} placeHolder="Posta  Kodu" required name="postCode" onChange={e => setPostCode(e.target.value)} className="form-control" />
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-success">
          <Row>
            <Col sm={10}>
              <input type="text" value={email} placeHolder="Email Bilgisini giriniz" required name="email" onChange={e => setEmail(e.target.value)} className="form-control" />
            </Col>
            <Col sm={2}>
              <input type="text" value={mobile} placeHolder="Mobil Telefon giriniz" required name="mobile" onChange={e => setMobile(e.target.value)} className="form-control" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <input type="text" value={freeText1} placeHolder="Son Bakiye Giriniz" required name="freeText1" onChange={e => setFreeText1(e.target.value)} className="form-control" />
            </Col>
            <Col sm={6}>
              <b>Aktif?</b>&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="checkbox" checked={active} required name="active" onChange={e => setActive(e.target.checked)} />
            </Col>
          </Row>
        </Container>
        &nbsp;
        <Container fluid className="border border-info">
          &nbsp;
          <Row>
            <Col sm={4}>
              <button type="button" onClick={handleSubmit} className="btn btn-success">Kaydet</button>
            </Col>
            <Col sm={4}>
              <button type="button" onClick={getAllCustomerList} className="btn btn-warning">Listele</button>
            </Col>
            <Col sm={4}>
              <button type="button" onClick={handleEditClick} className="btn btn-Info">Düzenle</button>
            </Col>
          </Row> &nbsp;
        </Container>
      </form>
      <div className="Form1">
        <table id="table-to-xls" className="Form1">
          <tr>
            <th>Seç</th>
            <th>Id</th>
            <th>Kod</th>
            <th>Müşteri Adı</th>
            <th>Müşteri Tipi</th>
            <th>Vergi Kodu</th>
            <th>Vergi Dairesi</th>
            <th>Adres</th>
            <th>Posta Kodu</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Aktif</th>
            <th>Satış Fiyatı</th>
            <th>Kaydeden</th>
            <th>Kayıt Tarihi</th>
          </tr>
          <tbody>
            {renderTableData()}
          </tbody>
        </table>
      </div>
      <div className="Form1">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Download as XLS" />
      </div>
      <div className="Form1">
        <MyScreenFooter screenName="Müşteri Yönetim Ekranı" userNameFooter={userName} conpanyIDFooter={companyId}></MyScreenFooter>
      </div>
      <Modal
        isOpen={openWarning}
        onRequestClose={() => setOpenWarning(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "30%" }
        }}
      >
        <h2>Uyarı!!!!</h2>
        <p>{warningMessage}</p>
        <br></br>
        <div>
          <button value="closeWarningModal" className="btn btn-success" onClick={closeWarningModal}>Kapat</button>
        </div>
      </Modal>
    </div>
  )
}

export default MyCustomerManagementScreen;
